﻿// this could be moved to the components project, with jQuery pulled in as an npm dependency

$(document).ready(function () {
    // Header //    
    correctAriaControls();
    setUpSearchBarShowHide();
    accountForAnOpenAlertBanner();
    // End Header //
    updateBackgroundImagesFromWidget();
    scrollToTop();
    setUpNewWindowLinks();
    enableTooltips();
    enableOwlCarousel();
    initiateRondellCarousel();
    setUpTabbedNavigation();
    setUpBlog();
    expandContributorInfo();
    setUpTeamMembers();
    setUpFitVids();
    setUpExternalLinks();
    applySecurityMeasuresToIframeEmbeds();
});

function correctAriaControls() {
    $('[aria-controls][data-target]').each(function () {
        var $this = $(this);
        var $elem = $($this.attr('data-target'));
        var id = $elem.attr('id');
        if (!id) return;
        $this.attr('aria-controls', id);
    })
}

function setUpSearchBarShowHide() {
    $('.header__bottom__search__close').click(function () {
        closeSearch();
    });

    $('.navbar-toggler.collapsed').click(function () {
        closeSearch();
    });

    const searchOutsideClickListener = (event) => {
        var $target = $(event.target);
        function clickedOutsideSearchBar() {
            return !$target.closest(".header__bottom__search").length;
        }
        function searchIsOpen() {
            return $('.header__bottom').hasClass("search-open");
        }

        if (clickedOutsideSearchBar() && searchIsOpen()) {
            closeSearch();
        }
    }

    const removeSearchClickListener = () => {
        document.removeEventListener('click', searchOutsideClickListener);
    }

    const closeSearch = () => {
        $('.header__bottom').removeClass("search-open");
        $('.header__bottom').addClass("search-close");
        removeSearchClickListener();
    }
}

function setUpNewWindowLinks() {
    //Set certain links to open in a new window
    $(".new-window").each(function () {
        $(this).attr("onclick", "window.open(this.href, '', 'resizable=yes,status=no,location=no,toolbar=no,menubar=no,fullscreen=no,scrollbars=yes,dependent=no'); return false;")
    });
}

function scrollToTop() {
    var scrollButton = $("#scrollToTop");
    $(window).scroll(function () {
        if ($(window).width() < 577 && $(this).scrollTop() > 100) {
            $(scrollButton).show();
        } else {
            $(scrollButton).hide();
        }
    });
}

function initiateRondellCarousel() {
    if (!$('.rondell-carousel').length)
        return;

    var windowWidth = 0;
    var containerWidth = 0;
    var containerHeight = 0;
    var controlWidth = 0;
    var controlHeight = 0;
    var controlMarginX = 0;
    var controlMarginY = 0;
    var maxImgWidth = 0;
    var maxImgHeight = 0;
    var radius = 0;

    instantiateRondellCarousel();

    $(window).resize(function () {
        resizeRondellCarousel();
    });

    $(window).on("orientationchange", function () {
        resizeRondellCarousel();
    });

    function instantiateRondellCarousel() {
        // Setup rondell carousel (recent blog posts widget)
        typeof $.rondell === "object" && $('.rondell-carousel').each(function () {
            getRondellDimensions($(this).outerWidth());
            $(this).rondell({
                preset: "carousel",
                center: { left: containerWidth / 2, top: containerHeight / 2 },
                randomStart: false,
                visibleItems: 1,
                opacityMin: 0,
                radius: { x: radius, y: 0 },
                itemProperties: { size: { width: maxImgWidth, height: maxImgHeight } },
                scaling: 1,
                fadeTime: 500,
                autoRotation: { enabled: false },
                lightbox: { enabled: false },
                captionsEnabled: false,
                pauseOnPlayingVideo: true,
                controls: { alwaysShow: true, margin: { x: controlMarginX, y: controlMarginY } },
                strings: { prev: '<span class="sr-only">Previous</span>', next: '<span class="sr-only">Next</span>' },
                touch: { preventDefaults: false }
            });
        });
    }

    function resizeRondellCarousel() {
        typeof $.rondell === "object" && $('.rondell-carousel').each(function () { // Reinitialize each Rondell carousel with its new size.
            var rondellCarousel = $(this).data('rondell');
            if (rondellCarousel) {
                getRondellDimensions($(this).outerWidth());
                $(this).css('width', 'auto').css('height', 'auto');
                rondellCarousel.center.left = containerWidth / 2;
                rondellCarousel.center.top = containerHeight / 2;
                rondellCarousel.radius.x = radius;
                rondellCarousel.visibleItems = 1;
                rondellCarousel.itemProperties.size.width = maxImgWidth;
                rondellCarousel.itemProperties.size.height = maxImgHeight;
                rondellCarousel.itemProperties.sizeFocused.width = maxImgWidth;
                rondellCarousel.itemProperties.sizeFocused.height = maxImgHeight;
                rondellCarousel.controls.margin.x = controlMarginX;
                rondellCarousel.controls.margin.y = controlMarginY;
                $(this).children('.rondell-control.rondell-shift-left').css('left', rondellCarousel.controls.margin.x).css('top', rondellCarousel.controls.margin.y);
                $(this).children('.rondell-control.rondell-shift-right').css('right', rondellCarousel.controls.margin.x).css('top', rondellCarousel.controls.margin.y);
                rondellCarousel.refresh();
            }
        });
    }

    function getRondellDimensions(newContainerWidth) {
        windowWidth = Math.max(window.innerWidth, document.documentElement.clientWidth);
        containerWidth = newContainerWidth;
        containerHeight = 400;
        controlWidth = windowWidth < 768 ? 0 : 55;
        controlHeight = 17;
        maxImgWidth = 360;
        maxImgHeight = 400;
        if (windowWidth < 425) {
            maxImgWidth = 250;
            maxImgHeight = 280;
            containerHeight = 300;
        }
        radius = Math.min(containerWidth / 2 - controlWidth, maxImgWidth * 1.5, 500);
        if (windowWidth < 768) {
            controlMarginX = containerWidth / 2 - controlWidth - radius / 3;
            controlMarginY = containerHeight + controlHeight;
        } else {
            controlMarginX = containerWidth / 2 - radius - controlWidth;
            controlMarginY = containerHeight / 2 - controlHeight;
        }
    }
}

function updateBackgroundImagesFromWidget() {
    $(".set-as-background").each(function () {
        var $this = $(this)
        var $target = $this.closest(".container").parent();
        if (!$target.length) {
            $target = $this.parent()
        }
        $target.addClass("has-background-image");
        var src = $this.attr("data-src");
        var hPosition = $this.attr("data-horizontal-position");
        var vPosition = $this.attr("data-vertical-position");
        var fit = $this.attr("data-fit");
        var height = $this.attr("data-height");

        if ($target.hasClass('home-background') || $target.parent().hasClass('home-background')) {
            // adapt this to our overlays
            $target.css({
                background: 'linear-gradient(rgba(0,65,106,.8),rgba(0,65,106,.8)),url(' + src + ')'
            });
        } else {
            $target.css({
                "background-image": "url(" + src + ")",
                "background-position": hPosition + " " + vPosition
            });
        }

        if (fit == "cover")
            $target.css({ "background-size": fit });
        else
            $target.css({ "background-repeat": fit });

        if (height)
            $target.css({ "height": height });

        $this.remove();
    });
}

function accountForAnOpenAlertBanner() {
    var alertBannerHeight = $(".header__alert").outerHeight();
    if (alertBannerHeight > 0) {
        adjustForAlertBanner(alertBannerHeight);

        $(window).resize(function () {
            var alertBannerHeight = $(".header__alert").outerHeight();
            adjustForAlertBanner(alertBannerHeight);
        });

        $(window).on("orientationchange", function () {
            var alertBannerHeight = $(".header__alert").outerHeight();
            adjustForAlertBanner(alertBannerHeight);
        });
    }


    function adjustForAlertBanner(alertBannerHeight) {
        var windowWidth = document.documentElement.clientWidth;
        var newHeight = 0;
        if (windowWidth > 1199) {
            newHeight = 120 + alertBannerHeight;
        } else {
            newHeight = 0;
        }
        $("#mainContent").attr("style", "margin-top: " + newHeight + "px;");
    }
}

function enableTooltips() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });
}

function enableOwlCarousel() {
    $(".owl-carousel").each(function () {
        var owl = $(this);

        owl.owlCarousel({
            nav: true,
            center: true,
            margin: 10,
            dotsContainer: '#owl-dots',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1200: {
                    items: 6,
                    center: true,
                    startPosition: 2
                }
            }
        });
        owl.find(".owl-dots .owl-dot").each(function () {
            $(this).attr("role", "button");
        });

        owl.find('.owl-dot').click(function () {
            $(".owl-carousel").trigger('to.owl.carousel', [$(this).index(), 300]);
        });

        var itemCount = owl.find('.product-card').length

        var currentItem = 0;
        owl.on('changed.owl.carousel', function (e) {
            currentItem = e.item.index;
        });

        var $rightArrow = owl.siblings('.pcw__arrow-nav').find('.fa-arrow-right');
        $rightArrow.click(function () {
            if (currentItem <= (itemCount - 3)) {//at the start
                goForward()
            }
        });

        var $leftArrow = owl.siblings('.pcw__arrow-nav').find('.fa-arrow-left');
        $leftArrow.addClass('disabled');
        $leftArrow.click(function () {
            if (currentItem >= 3) {
                goBack();
            }
        })

        function goForward() {
            var target = Math.min(currentItem + 5, itemCount - 3);
            if(target === itemCount - 3)
                $rightArrow.addClass('disabled')
            else
                $rightArrow.removeClass('disabled');

            $leftArrow.removeClass('disabled');

            owl.trigger('to.owl.carousel', [target, 300]);
        }

        function goBack() {
            var target = Math.max(2, currentItem - 5);
            if(target === 2)
                $leftArrow.addClass('disabled')
            else
                $leftArrow.removeClass('disabled');
            
            $rightArrow.removeClass('disabled');
            
            owl.trigger('to.owl.carousel', [target, 300]);
        }
    });
}

function setUpTabbedNavigation() {
    $(".tabbed-navigation ul li a").each(function () {
        var contentId = $(this).attr("href").replace('#', "");
        $(this).attr("href", "javascript:void(0)");
        $(this).attr("data-selectedTab", contentId);
    });
    window.dataLayer = window.dataLayer || []; 
    window.dataLayer.push({ 
        'page_path': window.location
    });
    $(".tabbed-navigation li").click(function () {
        //find the index of the nav item you clicked on
        var index = $(this).parent().children().index(this);
        var childLink = $(this).find("a")[0];

        //switch current section indicator
        $(".currentPage").removeClass("currentPage");
        $(childLink).addClass("currentPage");

        //fade out current section (live)
        $(".tab-sections .tab-section:visible").fadeOut();

        //fade in new section (live)
        $($(".tab-sections .tab-section")[index]).fadeIn();
          
        //update hash
        window.location.hash = $(childLink).attr("data-selectedTab");
        window.dataLayer.push({ 
            'page_path': window.location
        });
    
    });

    //get hash, if any
    var hash = window.location.hash.toString().replace('#', '');
    if (hash != "") {
        var selectedNavLink = $(".tabbed-navigation ul li a[data-selectedTab='" + hash + "']")[0];

        if (selectedNavLink != null) {
            $(selectedNavLink).addClass("currentPage");
            $(selectedNavLink).click();
        }
    } else {
        var firstNavLink = $(".tabbed-navigation ul li a")[0]
        if (firstNavLink !== null) {
            $(firstNavLink).addClass("currentPage");
        }
    }



}

function setUpBlog() {
    $(".show-more-tags-mobile").click(function () {
        if ($(".tag-filters").hasClass("hidden")) {
            //expand tags
            $(".tag-filters").removeClass("hidden");
            $(".filter-option.tags").attr("style", "display: block;");
            $(this).html("Close Tags");
            $(".show-more-tags").html("<span>Show Less</span><span class='sr-only'> Tags</span><i class='fas fa-angle-up'></i>");
        } else {
            //collapse tags
            $(".tag-filters").addClass("hidden");
            $(".filter-option.tags").hide();
            $(this).html("View Tags");
            $(".show-more-tags").html("<span>Show More</span><span class='sr-only'> Tags</span><i class='fas fa-angle-down'></i>");
        }
    });

}

function expandContributorInfo() {
    $(".contributor").click(function () {
        $(".contributorInfo").removeClass("hidden");
    });
}

function setUpTeamMembers() {
    $(".team-member").click(function () {
        
        if($(this).hasClass("show-description"))
        {
            $(this).removeClass("show-description");
            return;
        }
        
        //close other open dropdowns
        $(".team-member.show-description").each(function () {
            $(this).removeClass("show-description");
        });
        //expand current dropdown
        $(this).addClass("show-description");
    })
}

function setUpFitVids() {
    $('iframe[src*="youtube"]').parent().fitVids();
    $('img[alt="Interactive Video Player"]').css("width", "100%");
}

function setUpExternalLinks() {
    $(document).on("click", "a:not(.speedbump-link):not([data-lity])", function (event) {
        //check to see if it is an external link
        var $this = $(this)
        var href = $this.attr("href");
        var target = $this.attr("target");
        var isInternal = linkIsInternal(href);
        if (isInternal) {
            return;
        } else {
            event.preventDefault();
            $.get("/api/whitelist?url=" + encodeURIComponent(href), function (isWhitelisted) {
                if (isWhitelisted) {
                    if (!target)
                        location.href = href;
                    else {
                        var openSuccess = window.open(href, target);
                        if (!openSuccess) location.href = href;
                    }
                } else {
                    showSpeedBump(href, target);
                }
            });
        }
    });
}

function showSpeedBump(href, target) {
    var primaryButton = $("#speedbump .btn-primary")[0];
    var secondaryButton = $("#speedbump .btn-secondary")[0];

    //open #speedbump
    var speedbump = lity('#speedbump');

    //set up click events for buttons
    //MAKE THIS A NORMAL LINK INSTEAD, DUPLICATING THE href and target
    $(primaryButton).replaceWith(
        $('<a href="' + href + '" target="' + target + '" class="btn btn-primary speedbump-link">Leave 3Rivers</a>')
            .click(function () { speedbump.close(); })
    );
    $(secondaryButton).click(function () {
        //close #speedbump
        speedbump.close();
    });
}

function linkIsInternal(href) {
    // TODO make sure to test javascript:void(0) and "#id"
    return !href || href.indexOf("http") != 0
}

function applySecurityMeasuresToIframeEmbeds() {
    $(document).on('lity:ready', function (event, instance) {
        var content = instance.element(),
            $iframe = $("iframe", content)

        if (!$iframe.length)
            return

        fixHeight();
        applyMeasures($iframe);
    });

    $('iframe').each(function () {
        applyMeasures($(this));
    });

    function applyMeasures($iframe) {
        $iframe.attr('allowfullscreen', "false");
        $iframe.attr('allowpaymentrequest', "false");
        $iframe.attr('referrerpolicy', "no-referrer"); // this may need to be excluded from certain domains if it breaks their embeds
    }

    function fixHeight(){
        $(".lity-iframe-container").css("height", document.documentElement.clientHeight * .85);
    }
}